


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';

const LetterPlugin = () => import('@/modules/correspondence/components/LetterPlugin.vue');

@Component({
  components: { LetterPlugin }
})
export default class LetterTab extends Vue {
  @Prop({ type: Object }) conservatorship?: ConservatorshipDetails;
}
